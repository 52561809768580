import * as React from 'react';

import { IconPreview, IconDownload } from '../../Atoms/Icons/Icons';

import './DocumentView.scss';

const getSingleDocument = ({ name, size, link, target = '_blank', showName = true }) => {
  return (
    <div className="document_view_btns">
      {
        (name || size) &&
        <div className="details">
          <p>{showName && name}</p>
          <span>{size}</span>
        </div>
      }
      <div className="icons">
        <a href={link} target={target}>
          <IconPreview />
        </a>
        <a href={link} download={name ? name : 'document'}>
          <IconDownload />
        </a>
      </div>
    </div>
  );
};

const DocumentView = ({ data }) => {
  return (
    <div className="documents_container">
      {
        data.map((document, index) => (
          <div className="document_view_line" key={index}>
            {
              getSingleDocument(document)
            }
          </div>
        ))
      }
    </div>
  );
};

export default DocumentView;
