import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../../Layout";
import Seo from "../../../components/Seo/Seo";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageBanner from "../../../components/PageBanner/PageBanner";
import ShapeBackground from "../../../Molecules/ShapeBackground/ShapeBackground";

import TextVisuelTwoCol from "../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol";

import TitlePage from "../../../Molecules/TitlePage/TitlePage";

import IntroPage from "../../../components/ContentType/IntroPage/IntroPage";
import DocumentView from "../../../Molecules/DocumentView/DocumentView";

import "../engagements.scss";
import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import TitleBlock from "../../../Molecules/TitleBlock/TitleBlock";
import ScrollPageNav from "../../../components/ScrollPageNav/ScrollPageNav";

var classNames = require("classnames");

const FondationColas = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fluid {
            ...GatsbyImageSharpFluid
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const leftRightBlocks = [
    {
      text: "<p>L’ambition de Colas est le zéro accident. En ce sens, le Groupe assure la santé-sécurité de tous ses collaborateurs en respectant les dispositions légales en vigueur dans les pays où nous opérons. Colas œuvre également à travers des programmes de sensibilisation ad hoc et en mettant à disposition de ses collaborateurs des équipements adaptés aux besoin de chacun.</p><p>De ce fait, en septembre 2020, les « Règles Groupe » (règles de vie et règles qui sauvent) ont été lancées par Frédéric Gardès, PDG de Colas. Elles sont depuis progressivement déployées avec des supports appropriés (films, affiches, méthodes, …) pour en faciliter une compréhension effective et ainsi nous permettre d’atteindre, ensemble, une culture sécurité homogène.</p>",
      title:
        "Développer la culture santé et sécurité pour l’ensemble des parties prenantes",
      visuel: data.file.childImageSharp
    },
    {
      text: "<p>La protection des collaborateurs et la préservation de l’activité du Groupe contre les actes de malveillance est une priorité. Les enjeux se situent sur la prévention et l’anticipation des principaux risques et sur l’accompagnement des situations de crise.</p><p><strong>Les outils à dispositions de la sûreté et la gestion de crise sont les suivants :</strong></p><ul><li><strong>Le Golden Book :</strong> Fiches réflexes très synthétiques recensant les actions essentielles à entreprendre en situation d’urgence.</li><li><strong>Le Plan de Continuité d’Activité (PCA) :</strong> il couvre l’ensemble du personnel, des ressources, des services et des actions clés nécessaires pour gérer le processus de gestion de la continuité des activités.</li><li><strong>Le Manuel de gestion de crise :</strong> il présente les différentes étapes à suivre en cas de crise</li></ul>",
      title: "Déployer une démarche sûreté et gestion de crise",
      visuel: data.file.childImageSharp
    },
    {
      text: "<p>La protection des collaborateurs et la préservation de l’activité du Groupe contre les actes de malveillance est une priorité. Les enjeux se situent sur la prévention et l’anticipation des principaux risques et sur l’accompagnement des situations de crise.</p><p><strong>Les outils à dispositions de la sûreté et la gestion de crise sont les suivants :</strong></p><ul><li><strong>Le Golden Book :</strong> Fiches réflexes très synthétiques recensant les actions essentielles à entreprendre en situation d’urgence.</li><li><strong>Le Plan de Continuité d’Activité (PCA) :</strong> il couvre l’ensemble du personnel, des ressources, des services et des actions clés nécessaires pour gérer le processus de gestion de la continuité des activités.</li><li><strong>Le Manuel de gestion de crise :</strong> il présente les différentes étapes à suivre en cas de crise</li></ul>",
      title: "Déployer une démarche sûreté et gestion de crise",
      visuel: data.file.childImageSharp
    }
  ];

  return (
    <Layout>
      <Seo title="Fondation COLAS" description="Fondation COLAS" />
      <div
        className={classNames(
          "page_template",
          "fondation_colas"
        )}
      >
        <ScrollPageNav
          data={[
            {
              title: 'Title',
              section: 'block_0_section'
            },
            {
              title: 'Une collection d’art',
              section: 'block_1_section'
            },
            {
              title: 'Des œuvres d’art',
              section: 'block_2_section'
            },
            {
              title: 'Documents',
              section: 'block_3_section'
            },
            {
              title: 'De nouvelles missions à venir',
              section: 'block_4_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "Engagements", url: "/engagements" },
                currentPage: {
                  title: "Fondation Colas",
                  url: "/fondation-colas",
                },
              }}
            />
            <TitlePage color="color_white" title="LA FONDATION COLAS " />
          </div>
        </PageBanner>
        <IntroPage
          customClass="block_0_section"
          title="Title"
          description="lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem"
          visuel={data.file.childImageSharp}
          text="<p>Depuis sa création au début des années 1990, la Fondation Colas a eu pour vocation de promouvoir la peinture contemporaine en passant commande de toiles à des artistes pour sa collection ainsi que, aujourd’hui, des fresques d’art urbain pour les sites industriels du groupe Colas. Face à la montée en puissance des enjeux sociétaux sur l’ensemble des territoires, elle fait actuellement évoluer son positionnement.</p>"
          size="border" //wrapper
        />

        <section
          className={classNames("section_content", "block_1_section")}
        >
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page_xs">
              <TitleSection
                title="Une collection d’art contemporain sur la thématique de la route"
                type="arrow"
              />
              {leftRightBlocks.map((block, index) => (
                <TextVisuelTwoCol
                  key={index}
                  title={block.title}
                  orderInverse={(index % 2 == 0) ? true : false}
                  visuel={block.visuel}
                  extactHalf={(index % 2 == 0) ? false : true}
                  alignLeft={(index % 2 == 0) ? true : false}
                  alignTop={true}
                  text={block.text}
                  colorDescription="color_black"
                  textFullWidth={(index % 2 == 0) ? false : true}
                  smallVisuel={(index % 2 == 0) ? false : true}
                  extremeRight={(index % 2 == 0) ? true : false}
                  visuelAspectRatioMobile={1.34241245}
                  visuelAspectRatioDesktop={(index % 2 == 0) ? 1.04107143 : 0.699801193}
                  visuelMaxWidthDesktop={(index % 2 == 0) ? '100%' : 352}
                />
              ))}
            </div>
          </ShapeBackground>
        </section>

        <section
          className={classNames("section_content", "block_2_section")}
        >
          <ShapeBackground
            top="left"
            color="dark_bleu z-index-top"
            bottom="left"
          >
            <div className="wrapper_page_xs">
              <TitleSection
                title="Des œuvres d’art urbain sur les sites de Colas"
                type="arrow"
              />
              {leftRightBlocks.map((block, index) => (
                <TextVisuelTwoCol
                  key={index}
                  title={block.title}
                  orderInverse={(index % 2 == 0) ? true : false}
                  visuel={block.visuel}
                  extactHalf={(index % 2 == 0) ? false : true}
                  alignLeft={(index % 2 == 0) ? true : false}
                  alignTop={true}
                  text={block.text}
                  colorDescription="color_black"
                  textFullWidth={(index % 2 == 0) ? false : true}
                  smallVisuel={(index % 2 == 0) ? false : true}
                  extremeRight={(index % 2 == 0) ? true : false}
                  visuelAspectRatioMobile={1.34241245}
                  visuelAspectRatioDesktop={(index % 2 == 0) ? 1.04107143 : 0.699801193}
                  visuelMaxWidthDesktop={(index % 2 == 0) ? '100%' : 352}
                  colorTitle="color_white"
                  colorDescription="color_white"
                />
              ))}
            </div>
          </ShapeBackground>
        </section>

        <section className={classNames('section_content', 'block_3_section')}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="Documents"
              color="color_white"
            />
            <DocumentView
              data={[
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/",
                },
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/",
                },
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/",
                },
              ]}
            />
          </div>
        </section>

        <section className={classNames("section_content", "block_4_section")}>
          <div className="wrapper_page_xs">
            <TitleSection title="De nouvelles missions à venir" type="arrow" />
            <TextVisuelTwoCol
              orderInverse={false}
              extremeLeft={true}
              visuel={data.file.childImageSharp}
              title="Lorem Lorem Lorem"
              text="<p>Aujourd’hui, pour prendre en compte la montée en puissance des préoccupations sociétales et donner une dimension plus internationale à ses actions, La Fondation Colas souhaite élargir son positionnement à de nouvelles missions. Rendez-vous dans quelques mois pour en savoir plus !</p>"
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default FondationColas;
